$(document).on('turbolinks:load', function () {
  var $loginForm = $('.js-login-form');
  if ($loginForm.length > 0) {

    $loginForm.on('submit', function(e){
      // validate the captcha is filled
      var googleResponse = $('#g-recaptcha-response').val();
      if (!googleResponse) {
        return false;
      }
      return true;
    });

  }
});

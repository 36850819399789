$(document).on('turbolinks:load', function () {

  var trusted = $('.data-box').attr('data-box-trusted');

	// ready for review
	$('.data-box #ready_for_review').on('change', function(){
		if( $(this).is(':checked') ) {
			review_modal();
		}
	});

  function review_modal() {
    swal({
		  title: ('true' == trusted ? 'Product Publish' : 'Product Review Information'),
      text: review_text(),
		  icon: "info",
      buttons: {
        cancel: {
          text: "Continue Editing",
          value: false,
          visible: true,
          className: "",
          closeModal: true,
        },
        confirm: {
          text: ('true' == trusted ? 'Schedule & Publish' : 'Submit for Review'),
          value: true,
          visible: true,
          className: "",
          closeModal: true
        }
      }
    })
    .then((isConfirm) => {
      if (isConfirm) {
		 		$('a[data-save-clone]').trigger('click');
		 	} else {
		 		$('#ready_for_review').trigger('click');
		 	}
    });
  }

  function review_text(){

    if ('true' == trusted) {
      return "As a Trusted Creator your product does not need to be reviewed. Upon confirmation, your product will be scheduled and published."
    } else {
      return "Product reviews typically take between 3-5 days, depending on the current queue. After submitting for review, you will no longer be able to edit your product until it's published or sent back for changes. Please take this time to ensure your product is ready for public display!"
    }

  }

});

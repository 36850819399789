$(document).on('turbolinks:load', function () {
  var $prodFiles = $('.js-productReview-files-flag');
  var $primaryInfo = $('.js-productReview-primary-info-flag');
  var $categoriesSoftwareLicense = $('.js-productReview-categories-software-licensing-flag');
  var $productPage = $('.js-productReview-product-page-flag');
  var $prodImages = $('.js-productReview-images-flag');
  var $prodVariations = $('.js-productReview-variations-flag');
  var $docsFaqs = $('.js-productReview-flag-docs-faq');
  var $prodReviewSubmitBtn = $('.js-submit-product-review')

  var $flagsAndSubmit = [$prodFiles, $primaryInfo, $categoriesSoftwareLicense, $productPage, $prodImages, $prodVariations, $docsFaqs, $prodReviewSubmitBtn]

  $flagsAndSubmit.map(function(e){
    e.on('click', function(e){
      var $flag = $(e.target)
      var $isFlagged = $flag.attr('data-isflagged')
      updateFlag($flag, $isFlagged);
      sendFlag($flag)
    })
  })
})


function updateFlag($flag, isFlagged){
  var $navFlag = findNavFlag($flag)

  if (isFlagged === 'false'){
    $flag.removeClass('far').addClass('fas flagged');
    $flag.attr('data-isflagged', true)
    $navFlag.removeClass('d-none').addClass('d-flex')

  } else if(isFlagged === 'true') {
    $flag.removeClass('fas flagged').addClass('far');
    $flag.attr('data-isflagged', false)
    $navFlag.removeClass('d-flex').addClass('d-none')
  }
}


function sendFlag(flag) {
  var flagSelectorDataAttr = flag.attr('data-isflagged')
  var isFlagSelectorDataTrue = flagSelectorDataAttr === 'true'
  var alertUncheckedFlag = flagSelectorDataAttr && !isFlagSelectorDataTrue && alert("Please don't forget to remove this section from the reviewer message")
  // short circuit check if the data attribute exists, and if it's false, then create alert message
  if(flag.hasClass('js-productReview-files-flag')){
    $('#product_review_product_files').val(flagSelectorDataAttr)
    isFlagSelectorDataTrue && addFlaggedAndSubmitToTemplate('productFiles')
    alertUncheckedFlag
  }
  else if(flag.hasClass('js-productReview-primary-info-flag')){
    $('#product_review_primary_info').val(flagSelectorDataAttr)
    isFlagSelectorDataTrue && addFlaggedAndSubmitToTemplate('primaryInfo')
    alertUncheckedFlag
  } 
  else if (flag.hasClass('js-productReview-categories-software-licensing-flag')){
    $('#product_review_categories_software_and_licensing').val(flagSelectorDataAttr)
    isFlagSelectorDataTrue && addFlaggedAndSubmitToTemplate('categoriesSoftwareLicensing')
    alertUncheckedFlag
  }
  else if (flag.hasClass('js-productReview-product-page-flag')){
    $('#product_review_product_page').val(flagSelectorDataAttr)
    isFlagSelectorDataTrue && addFlaggedAndSubmitToTemplate('productPage')
    alertUncheckedFlag
  }
  else if (flag.hasClass('js-productReview-images-flag')){
    $('#product_review_image_gallery').val(flagSelectorDataAttr)
    isFlagSelectorDataTrue && addFlaggedAndSubmitToTemplate('imagesGallery')
    alertUncheckedFlag
  }
  else if(flag.hasClass('js-productReview-variations-flag')){
    $('#product_review_product_variations').val(flagSelectorDataAttr)
    isFlagSelectorDataTrue && addFlaggedAndSubmitToTemplate('productVariations')
    alertUncheckedFlag
  }
  else if(flag.hasClass('js-productReview-flag-docs-faq')){
    $('#product_review_docs_and_faq').val(flagSelectorDataAttr)
    isFlagSelectorDataTrue && addFlaggedAndSubmitToTemplate('docsFaq')
    alertUncheckedFlag
  } 
  else if(flag.hasClass('js-submit-product-review')){
    addFlaggedAndSubmitToTemplate('submit')
  }
}

function findNavFlag($flag){
  // this function splits up the list of class names on a flag, and then appends '-nav' to the found class name
  $flagClassAry = $flag.attr('class').split(" ")
  var findFlag = $flagClassAry.find((className)=> className.startsWith('js-productReview'))
  var classNav = findFlag + "-nav"
  return $("." + classNav)
} 

function addFlaggedAndSubmitToTemplate(flaggedSection){
  var templateString = ''
  var summernoteTarget = $('.js-textEditor-productReview')

  switch(flaggedSection){
    case 'productFiles' :
      templateString +='<p> Product Files </p>'
    break
    case 'primaryInfo' :
      templateString +=`<p> Primary Information </p>`
    break
    case 'categoriesSoftwareLicensing' :
      templateString +=`<p> Categories Software and Licensing </p>`
    break
    case 'productPage' :
      templateString +='<p> Product Page </p>'
    break
    case 'imagesGallery' :
      templateString +='<p> Images Gallery </p>'
    break
    case 'docsFaq' :
      templateString +='<p> Documents and FAQ </p>'
    break
    case 'productVariations' :
      templateString +='<p> Product Variations </p>'
    break
    default :
      templateString += '<br>'
  }

  summernoteTarget.summernote('pasteHTML', templateString)//adds text to summernote
  $('#product_review_notes').val(summernoteTarget.summernote("code"))
}
$(document).on('turbolinks:load', function () {

  var $form = $('.edit_product');
  if ($form.length > 0) {
    // Initialize commission rate variables
    var max_rate 					= $('#commission-rate').data('commission-rate')
    , 	dev_fund 					= $('#dev-fund-rate').data('dev-fund')
    , 	max_rate_dollars 	= $('#commission-rate').data('commission-amount')
    , 	dev_fund_dollars 	= $('#dev-fund-rate').data('dev-fund-amount')
    , 	rate 							= 0
    ,		total_rate 				= max_rate - rate - dev_fund
    ,		total_dollars 		= max_rate_dollars - dev_fund_dollars;

    // Check if the product has more than 100% being distributed to collaborators
    $('.js-collaborator-slider').each(function(){
    	rate += parseInt($(this).val());
    });
    if ( rate > max_rate ) {
      alert('Re-adjust your commission rates!')
    }

    // Update data box stuff
    $('#commission-left').text(total_rate);
    $('#total-per-product').text('$' + total_dollars);

    // calculate total of sub vendors commissions
    var commissionTotal = 0;
    $('.sub-vendor-commissions').each(function(){
      commission_value = Math.round($(this).find('#commission-value').data('commission-value'));
      commissionTotal += commission_value;
      var product_price = $('#product_price').attr('data-price') > 0 ? $('#product_price').attr('data-price') : 0
      ,		setPrice 			= ( ($('.data-box').attr('data-box-processing-fees') * commission_value) / 100).toFixed(2)
      ,   totalPrice    = (setPrice / 100).toFixed(2)

      if (totalPrice) {
        $(this).find('.amount').text('$'+totalPrice);
      }
    });
    $('#total-vendor-commissions').text(commissionTotal);

    // in admin, remove duplicates from commission vendor dropdown filter
    $('select[data-type="vendor"]').find('option').each(function(index, value){
  		if ($('option[value="' + $(this).val() + '"]').size() > 1) {
      	$(this).remove();
      }
    });

    // Update collaborator amounts when slider is dragged
    $form.on('input', '.js-collaborator-slider', function(e){
      calculateCollaboratorAmounts(this);
    });

    // Ensure we display amounts correctly after adding a new collaborator
    $('.js-collaborators-wrap').on('cocoon:after-insert', function(e, insertedItem) {
      calculateCollaboratorAmounts($(insertedItem).find('.js-collaborator-slider'));
    });
  }

});

function calculateCollaboratorAmounts(el){
  var $el = $(el);
  var value = $el.val();

  var setPrice = $('.data-box').attr('data-box-processing-fees');
  var totalPrice = (setPrice / 100) * (value / 100);
  var totalPriceRounded = totalPrice.toFixed(2);

  var $wrap = $el.closest('.js-collaborator-wrap');
  $wrap.find('.js-collaborator-percentText').text(value);
  $wrap.find('.js-collaborator-amountText').text("$" + totalPriceRounded);
  // TODO update data box line item for this collaborator
}

$(document).on('turbolinks:load', function () {

  if ($('form.edit_user').length) {
    var $payoutMethodSelect = $('.js-payout-method-select');

    $payoutMethodSelect.on('change', function() {
      let $selected = $(this).val();
      let $paypalEmailInput = $('.js-paypal-email-input-wrapper');
      let $stripeConnect = $('.js-stripe-connect-wrapper');

      if ($selected === 'paypal') {
        $stripeConnect.removeClass('show');
        $paypalEmailInput.addClass('show');
      } else if ($selected === 'stripe') {
        $paypalEmailInput.removeClass('show');
        $stripeConnect.addClass('show');
      } else {
        $paypalEmailInput.removeClass('show');
        $stripeConnect.removeClass('show');
      };
    });
  };
});
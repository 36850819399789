import Cookies from 'js-cookie';

$(document).on('turbolinks:load', function () {
  $('.js-admin-menu_toggle, .js-creator-menu_toggle').on('click', function(e) {
    var lastSetting = $('.js-admin-menu, .js-creator-menu').hasClass('show');
    Cookies.set('manageui_menu_closed', !lastSetting);
  });

  if (matchMedia) {
    const mq = window.matchMedia("(max-width: 1200px)");
    mq.addListener(menuWidthChange);
    menuWidthChange(mq);
  }

  function menuWidthChange(mq) {
    if (mq.matches) {
      closeBackendMenu();
    } else {
      openBackendMenu();
    }
  }

  function closeBackendMenu() {
    Cookies.set('manageui_menu_closed', true);
    $('.js-admin-menu, .js-creator-menu').removeClass('show');
  }

  function openBackendMenu() {
    Cookies.set('manageui_menu_closed', false);
    $('.js-admin-menu, .js-creator-menu').addClass('show');
  }
});

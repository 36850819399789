const initializeAnalytics = () => {
  const path = window.location.pathname;

  const isTrackedPage = (path) => {
    const firstPathSegment = path.split("/")[1];
    return firstPathSegment !== "admin";
  };

  const isTrackingSetUp = () => {
    return !!window.dataLayer && !!window.gtmId && !!window.gaId;
  };

  if (isTrackedPage(path) && isTrackingSetUp()) {
    const logAnalytics = (eventName, eventData, updatedProductViewInfo = {}) => {
      try {
        const userId = typeof window.currentUser !== "undefined" ? window.currentUser.id : null;

        let gtmData = {
          user_id: userId,
          gtm: {
            pageTitle: eventData.pageTitle,
            pagePath: eventData.pagePath,
            eventName: eventName,
            elementTarget: eventData.elementTarget || "",
            productSlug: eventData.productSlug || "",
            productViewInfo: updatedProductViewInfo,
          },
        };

        window.dataLayer.push(gtmData);

        if (typeof window.gtag === "function") {
          gtag("event", eventName, {
            ...eventData,
            send_to: window.gaId,
          });
        };
      } catch (error) {
        console.warn("Error logging analytics: " + error.message);
      };
    };

    const updateProductData = () => {
      if (isTrackedPage(path) && isTrackingSetUp()) {
        try {
          return window.productData ? (({ image_data, content, ...rest }) => rest)(window.productData) : {};

        } catch (error) {
          console.warn("Error updating product view info: " + error.message);
        };
      };
    };

    const getEcommerceStep = (path) => {
      if (isTrackedPage(path) && isTrackingSetUp()) {
        try {
          switch (true) {
            case /\/products\/[^/]+\/?$/.test(path):
              return "view_item";
            case /\/cart\/checkout\/?$/.test(path):
              return "begin_checkout";
            case $(document.body).text().includes("Order completed successfully!"):
              return "purchase";
            default:
              return null;
          };

        } catch (error) {
          console.warn("Error getting ecommerce step: " + error.message);
        };
      };
    };

    const handleEcommerceClick = (event) => {
      if (isTrackedPage(path) && isTrackingSetUp()) {
        try {
          const clickedElement = $(event.target);
          const updatedProductViewInfo = updateProductData(window.productData);

          if (clickedElement.hasClass("btn-add-to-cart")) {
            logAnalytics("add_to_cart", {}, updatedProductViewInfo);
          } else if (clickedElement.closest(".card-product").length) {
            logAnalytics("product_click", {
              elementTarget: clickedElement[0].outerHTML,
            }, updatedProductViewInfo);
          };
          
        } catch (error) {
          console.warn("Error handling ecommerce click: " + error.message);
        };
      };
    };

    $(document).on("click", handleEcommerceClick);

    try {
      if (isTrackedPage(path) && isTrackingSetUp()) {
        const updatedProductViewInfo = updateProductData(window.productData);
        const pageViewInfo = {
          pagePath: path,
          pageTitle: document.title,
        };

        if ($("script[src*='googletagmanager.com/gtag/js']").length === 0) {
          const script = $("<script>", {
            src: `https://www.googletagmanager.com/gtag/js?id=${window.gtmId}`,
            async: true
          });
          $("head").append(script);
        };

        logAnalytics("page_view", pageViewInfo)

        if (getEcommerceStep(path)) {
          logAnalytics(getEcommerceStep(path), pageViewInfo, updatedProductViewInfo);
        };
      };
    } catch (error) {
      console.warn("Error initializing analytics: " + error.message);
    };
  };
};

$(document).on('turbolinks:load', initializeAnalytics);

$(document).on('turbolinks:load', function(){

  var $adminOrderForm = $('.js-admin-order-form');

  if ($adminOrderForm.length > 0) {

    var $orderSubTotal = $('.js-admin-order-sub-total');
    var $taxAmount = $('.js-admin-order-tax-amount');
    var $orderTotal = $('.js-admin-order-total-amount');
    var $orderItems = $('.js-admin-order-item');

    if ($orderItems.length) {
      $orderItems.each(function() {
        fieldsInit(this);
      });
    };

    $('.new_order, .edit_order').on('cocoon:after-insert', function(e, insertedItem) {
      fieldsInit(insertedItem);
    }).on('cocoon:before-remove', function(e, insertedItem) {
      var $itemPrice = $(insertedItem).find('.js-admin-order-item-price').val();
      if ($itemPrice) calcSubTotal($itemPrice);
    });

    $orderSubTotal.on('change input', function(){
      (!$(this).val() || $(this).val() <= 0) ? $taxAmount.val(0) : null;
      calcOrderTotal();
    });

    $taxAmount.on('input', function(){
      calcOrderTotal();
    });

    function calcSubTotal(subtract = 0) {
      if (subtract) {
        $orderSubTotal.val(parseInt($orderSubTotal.val()) - parseInt(subtract)).trigger('change');
      } else {
      
        let $nestedItems = $adminOrderForm.find('.nested-fields')
        let $totalPrice = $.map($nestedItems, function(el){
          var $price = $(el).find('.js-admin-order-item-price').val();
          return $price ? parseInt($price) : 0;
        }).reduce((sum, price) => sum + price);

        $orderSubTotal.val($totalPrice).trigger('change');
      }
    };

    function calcOrderTotal(){
      let subTotal = parseInt($orderSubTotal.val()) || 0;
      let taxAmount = parseInt($taxAmount.val()) || 0;
      $orderTotal.val(subTotal + taxAmount);
    }

    function fieldsInit(element) {
      let $productSelects = $('.js-product-select');
      let $variantSelect = $(element).find('.js-product-variant-select');
      let $itemPrice = $(element).find('.js-admin-order-item-price');
      let $itemName = $(element).find('.js-admin-order-item-name');
      let $itemVendorId = $(element).find('.js-admin-order-item-vendor-id');
      let $itemSaleId = $(element).find('.js-admin-order-item-sale-id');

      $.each($productSelects, function(i, el){
        $(el).attr('data-number', i); // set indexes for updating variant selects
      });

      $('.js-select').select2({ width: '100%' }); // re-initialize select2

      $productSelects.on('change', function(){
        let $this = $(this);
        let val = $this.find('option:selected').val();
        let inputNumber = $this.data('number');
        $itemPrice.prop('disabled', false);

        if (val !== ''){
          getProductVariants(val, inputNumber);
          getProductDetails(val, $itemName, $itemPrice, $itemVendorId, $itemSaleId);
        };
      });

      $variantSelect.on('change', function(){ 
        let val = $(this).val();
        if (val) {
          getVariantPrice(val, $itemPrice);
        } 
      });

      $itemPrice.on('change input', function(){
        calcSubTotal();
      });
    };

    function getProductDetails(id, $nameField, $priceField, $vendorIdField, $saleIdField) {
      $.ajax({
        type: 'GET',
        url: '/api/v1/products/' + id,
        dataType: 'json',
        success: function(response){
          updateItemDetails($nameField, $priceField, $vendorIdField, $saleIdField, response);
        }
      });
    };

    function getProductVariants(id, inputNumber) {
      $.ajax({
        type:'GET',
        url: '/product_variants?product=' + id + '&input_number=' + inputNumber,
        dataType: 'script'
      });
    };

    function getVariantPrice(id, $priceField) {
      $.ajax({
        type: 'GET',
        url: '/api/v1/product_variants/' + id,
        dataType: 'json',
        success: function(response) {
          $priceField.val(response['price']).trigger('change');
        }
      });
    };

    function updateItemDetails($nameField, $priceField, $vendorIdField, $saleIdField, productObj) {
      $priceField.val(productObj['price']).trigger('change');
      $nameField.val(productObj['title']);
      $vendorIdField.val(productObj['user_id']);
      $saleIdField.val(productObj['sale_campaign_id']);

      if (productObj['sale_campaign_id']) {
        $priceField.parent().next('.js-sale-label').text('Item on Sale!');
      } else {
        $priceField.parent().next('.js-sale-label').text('');
      }
    };
  };
});
import { text_to_slug } from '../../../util/methods';

$(document).on('turbolinks:load', function () {

  // Download files destroy capability
  $(document).on('click', '.js-reset-download', function(){
    var $this = $(this)
    ,   $parent = $this.closest('.js-download-wrapper');
    $parent.hide();
    $parent.find('.js-download-destroy-target').val($parent.data('product-download'));
  });

  /* Datepicker */
	$('.js-product-published_at').flatpickr({
		enableTime: true,
		minuteIncrement: 30,
		onChange: function(selectedDates, dateStr, instance){
			$('#product_published_at').val($('#published_at').val())
		}
  })

	$('.flatpickr-minute').val('00')
	$('#product_published_at').val($('#published_at').val())
})

function toggle_conditional_field(type, action) {

	if ('hide' == action) {
		$('div[data-conditional-hide="'+type+'"]').hide();
	} else {
		$('div[data-conditional-hide="'+type+'"]').show();
	}
}

function loadingMessages() {

	var type = $('body').attr('class')

	if ('layout-products-edit' == type) {
		var text = 'Updating product'
	} else if ('layout-products-create' == type || 'layout-products-new' == type) {
		var text = 'Creating product'
	} else if ('layout-vendor_emails-new' == type) {
		var text = 'Creating email'
	} else if ('layout-vendor_emails-edit' == type) {
		var text = 'Updating email'
	} else {
		var text = 'Saving'
	}

	$('body').addClass('loading').prepend('<div class="loading-messages">'+text+'...</div>');
}

function isNumberKey(evt){
   var charCode = (evt.which) ? evt.which : event.keyCode
   if (charCode > 31 && (charCode < 48 || (charCode > 57 && charCode != 190 && charCode != 110 ) ) && charCode != 46)
      return false;

   return true;
}

// sluggify product title
$(document).on('keyup', '#product_title', function(){

  if(window.location.pathname === '/creator/products/new')
	  $('#product_slug').val( text_to_slug($(this).val()) )

}).on('keypress', '#product_slug, #user_info_attributes_shop_slug', function(e) {

  if (e.which==32 || (e.which < 48 && e.which !== 45 ) || (e.which > 57 && e.which < 65) || (e.which > 90 && e.which < 97) || e.which > 122 )
    return false;

}).off('click', 'a[data-save-clone]').on('click', 'a[data-save-clone]', function(e){
	e.preventDefault();

	var action_id = $(this).closest('.js-form-wrap').find('.simple_form').attr('ID');
	$('#error_explanation').remove();
	loadingMessages();
	setTimeout(function(){
		$('#'+action_id)[0].submit();
	},100);

}).on('change', '#ready_for_review', function(){

	var $box = $('.data-box, .sticky-box');
	var trusted = $box.attr('data-box-trusted');
	var submit = $('a[data-save-clone]');
	var val = 'true' == trusted ? 'Schedule & Publish' : 'Submit for Review';

	if( $(this).is(':checked') ) {
		submit.removeClass('btn-primary').addClass('btn-danger').text(val);
		$('.simple_form').find('#submit_for_review').val('true');
	} else {
		submit.removeClass('btn-danger').addClass('btn-primary').text('Save Draft');
		$('.simple_form').find('#submit_for_review').val('');
	}

}).on('change', '.product_category_models', function(){

	if( $(this).is(':checked') ) {
		toggle_conditional_field('misc-data', 'show')
	} else {
		toggle_conditional_field('misc-data', 'hide')
	}

}).on('change','.product_category_surfacing', function(){

	if( $(this).is(':checked') ) {
		toggle_conditional_field('render-engine', 'show')
	} else {
		toggle_conditional_field('render-engine', 'hide')
	}

}).on('change', '.product_category_render-setups', function(){

	if( $(this).is(':checked') ) {
		$('.radio_buttons.product_info_license').find('label').not('label[for="product_info_attributes_license_gpl"]').hide()
	} else {
		$('.radio_buttons.product_info_license').find('label').not('label[for="product_info_attributes_license_gpl"]').show()
	}

}).on('change', '.price-in-dollars', function(){

  var val = $(this).val().replace(/\,/g,"");
  var newVal = val * 100.00;

  $(this).closest('.price-form-group').find('.price-in-cents').val(newVal).attr('data-price', newVal);
});

$(document).on('turbolinks:load', function(){

  $userSelect = $('.js-user-select')
  $selectSpinner = $('.js-select2-spinner')

  $userSelect.select2({
    placeholder: "Search by username...",
    ajax: {
      url: '/users/autocomplete',
      dataType: 'json',
      delay: 500,
      data: function(params){
        if (params.term && params.term.length > 0) $selectSpinner.show() //display spinner when user starts typing

        return {
          query: params.term
        }
      },
      processResults: function(data) {
        if (data) $selectSpinner.hide() //hide spinner when API returns a response

        data = $.map(data, function (obj) {
          obj.text = obj.username; // select2 looks for a text key in data to display as option
          return obj;
        })

        return {
          results: data
        }
      }
    },
  })

  $userSelect.on('select2:select', function(e) {
    let data = e.params.data;
    let options = `<option value=${data.id} selected>${data.text}</option>`
    $userSelect.empty().append(options).val(data.id).trigger('change')
  });    
})


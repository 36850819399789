import { displayFlashMessage, showLoadingMessage, hideLoadingMessage } from '../../../util/methods';

$(document).on('turbolinks:load', function() {
  /*
  Besides creating/editing a version, we are combining the following capabilities in the version form without having to reload the page:
  - upload or delete product files
  - create or destroy folders
  - sort/reorganize folders
  - sort/reorganize product files in and out of folders
  */

  var $newVersionForm = $('.new_product_version');
  var $versionEditForm = $('.edit_product_version');

  if ($newVersionForm.length || $versionEditForm.length) {
    var $versionFilesContainer = $('#js-version-files-container');
    var $downloadsContainer = $('#js-downloads-container');
    var $addFilesBtn = $('#js-add-files');
    var $downloadFolders = $('.js-sortable-folder');
    var $downloadFolderForm = $('form#new_download_folder');
    var $downloadFoldersContainer = $('.js-download-folders-container');
    var $noFolderDownloadsContainer = $('#no-folder-downloads');
    var $addFolderBtn = $('#js-add-folder-btn');
    var $hideFolderFormBtn = $('#js-hide-folder-form-btn');
    var $downloadFormContainer = $('.download-form-container');
    var $folderFormContainer = $('.folder-form-container');
    var $downloadsModal = $('#js-downloads-modal');

    /* Nested version downloads -----------------------------------------------*/
    // Code in this section is a work around to make cocoon insert multiple nested fields in one click

    // set number of nested fields for cocoon to insert
    $addFilesBtn.data('count', 0);

    // queue for keeping track of selected downloads for cocoon insert
    var filesToAdd = new Map();

    // for keeping track of already added files
    var nestedFileIDs = [];

    $addFilesBtn.on('click', function() {
      showLoadingMessage('Adding selected downloads');
    });

    $versionFilesContainer.on('cocoon:before-insert', function(e, itemToInsert) {
      var $itemToInsert = $(itemToInsert);

      // get first inserted key-value pair in filesToAdd
      var firstEntry = filesToAdd.entries().next().value;

      if ($itemToInsert && firstEntry.length) {

        var fileID = firstEntry[0];
        var fileName = firstEntry[1];

        // display file name & store file id
        $itemToInsert.find('input.form-control').attr('placeholder', fileName);
        $itemToInsert.find('input[id*="download_id"]').val(fileID);
      }
    }).on('cocoon:after-insert', function(e, insertedItem) {
      var $insertedItem = $(insertedItem);
      var downloadID = $insertedItem.find('input[id*="download_id"]').val();

      // remove file from queue and track id
      filesToAdd.delete(downloadID);
      nestedFileIDs.push(downloadID);

      // when filesToAdd is empty
      if (!filesToAdd.size) {
        $downloadsModal.modal('hide');
        $addFilesBtn.data('count', filesToAdd.size).addClass('disabled');
        hideLoadingMessage();
      }
    });
    /* End of cocoon-related code -----------------------------------------------*/

    /* ---------------------------------------------------------------------------
      We populate and clean out nestedFileIDs on modal show & hide.
      This way we'll always have an updated reference to what's currently in the Version Files section of the form.
    ----------------------------------------------------------------------------*/
    $downloadsModal.on('show.bs.modal', function() {
      // iterate over nested version files to get download_ids of active ones
      $('input[id*="download_id"]').each(function(){
        var $this = $(this);
        var hidden = $this.parent('div.nested-fields').css('display') === 'none';
        if (!hidden) {
          nestedFileIDs.push($this.val());
        }
      });

      // preselect downloads that are already added to the version files section
      nestedFileIDs.forEach((downloadID) => $(`.js-download-item-checkbox[value=${downloadID}]`).prop('checked', true)); 
    });

    $downloadsModal.on('hide.bs.modal', function() {
      nestedFileIDs.forEach((downloadID) => $(`.js-download-item-checkbox[value=${downloadID}]`).prop('checked', false));

      // using the splice method to empty out the nestedFileIDs array so our checkbox listeners can still reference this object
      nestedFileIDs.splice(0, nestedFileIDs.length);
    });

    /* Download Folder ---------------------------------------------------------*/
    if ($downloadFolders.length) {
      $downloadFolders.each(function() {
        addFolderElementListeners(this, nestedFileIDs, filesToAdd);
      });
    }

    $addFolderBtn.on('click', function(e){
      e.preventDefault();
      var $this = $(this);
      $downloadFormContainer.hide();
      $folderFormContainer.show();
      $this.hide();
      $hideFolderFormBtn.show()
    });

    $hideFolderFormBtn.on('click', function(e) {
      e.preventDefault();
      var $this = $(this);
      $folderFormContainer.hide();
      $downloadFormContainer.show();
      $this.hide();
      $addFolderBtn.show();
    });

    // New folder form
    $downloadFolderForm.on('submit', function() {
      showLoadingMessage('Creating Folder...');
    }).on('ajax:success', function(e, data, status, xhr) {
      hideLoadingMessage();
      var parsedData = JSON.parse(data);
      if (parsedData['success']) {
        var $folder = $(parsedData['html']);
        addFolderElementListeners($folder[0], nestedFileIDs, filesToAdd);
        $downloadFoldersContainer.append($($folder));
        $downloadsContainer.animate({ scrollTop: $folder.offset().top }, 500);
        displayFlashMessage('success', 'Successfully created download folder!');
        $(this)[0].reset();
        $addFolderBtn.trigger('click');
      } else {
        displayFlashMessage('danger', 'Failed to create download folder!');
      }
    });
    /* End of Download Folder-related code --------------------------------------*/

    addCheckBoxListeners($noFolderDownloadsContainer, nestedFileIDs, filesToAdd);

    $('.js-sortable-download-item').each(function() {
      var $this = $(this);
      var $deleteBtn = $this.find('.js-delete-download');
      var elementName = $this.find('.js-file-name').text();
      addElementRemover($deleteBtn, $this, elementName);
    });

    $(document).one('turbolinks:before-cache', function() {
      // Bootstrap's modals break if Turbolinks caches them in their "open" state
      // the downloads modal needs to be manually taken down before navigating away
      // can’t rely on the modal('close') method because it’s asynchronous
      if ($('body').hasClass('modal-open')) {
        $downloadsModal.hide().removeAttr('aria-modal').attr('aria-hidden', 'true');
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open');
      }

      // destroy select2 dropdown to prevent duplication on reload
      $('#js-version-variant-select').select2('destroy');
    });
  }

  // for deletion of versions
  $('.js-delete-version').each(function() {
    var $this = $(this);
    var $parentElement = $this.closest('tr');
    var elementName = $parentElement.children('td:first').text();
    addElementRemover($this, $parentElement, elementName);
  });
});

function addFolderElementListeners(folderElement, nestedFileIDs, filesToAdd) {
  // sets up necessary listeners for folder nodes

  var $folder = $(folderElement);
  var $collapsibleDownloadsContainer = $folder.find('.js-download-items-container');
  var $folderToggler = $folder.find('.folder-toggler');
  var $folderIcon = $folder.find('.folder-icon');
  var $deleteFolderBtn = $folder.find('.js-delete-folder');
  var folderName = $folder.find('.js-folder-name').text();

  addCollapsibleListener($collapsibleDownloadsContainer, $folderToggler, $folderIcon);
  addCheckBoxListeners($collapsibleDownloadsContainer, nestedFileIDs, filesToAdd);
  addElementRemover($deleteFolderBtn, $folder, folderName);
}

function addCollapsibleListener($collapsibleDownloadsContainer, $folderToggler, $folderIcon) {
  $collapsibleDownloadsContainer.on('hidden.bs.collapse', function() {
    $folderToggler.html('Open Folder');
    $folderIcon.removeClass('fa-folder-open').addClass('fa-folder');
  });

  $collapsibleDownloadsContainer.on('shown.bs.collapse', function() {
    $folderToggler.html('Close Folder');
    $folderIcon.removeClass('fa-folder').addClass('fa-folder-open');
  });
}

function addCheckBoxListeners($downloadItemsContainer, nestedFileIDs, filesToAdd) {
  // takes in a parent node and adds listeners to its children checkboxes

  var $addFilesBtn = $('#js-add-files'); // this element keeps track of the number of nested fields cocoon will insert
  var $folderCheckBox = $downloadItemsContainer.closest('.js-sortable-folder').find('.js-download-folder-checkbox');

  $downloadItemsContainer.on('change', '.js-download-item-checkbox', function() {
    // every time a download item checkbox is checked/unchecked, we need to cross reference downloads that has already been added (nestedFileIDs)
    // if valid, add/remove file to the queue (filesToAdd) & increase/decrease cocoon counter ($addFilesBtn.data('count'))
    var $checkbox = $(this);
    var downloadID = $checkbox.val();
    var fileName = $checkbox.data('file-name');
    var count = parseInt($addFilesBtn.data('count'));
    if ($checkbox.is(':checked')) {
      if (!nestedFileIDs.includes(downloadID)) {
        filesToAdd.set(downloadID, fileName);
        $addFilesBtn.data('count', count += 1);
      }
    } else {
      // if a download checkbox is being unchecked, uncheck folder checkbox if it is currently checked
      if ($folderCheckBox.length && $folderCheckBox.is(':checked')) {
        $folderCheckBox.prop('checked', false);
      }
      if (count > 0 && !nestedFileIDs.includes(downloadID)) {
        filesToAdd.delete(downloadID);
        $addFilesBtn.data('count', count -= 1);
      }
    }

    count ? $addFilesBtn.removeClass('disabled') : $addFilesBtn.addClass('disabled');
  });

  if ($folderCheckBox.length) addFolderCheckBoxListener($folderCheckBox, $downloadItemsContainer);
}

function addFolderCheckBoxListener($folderCheckBox, $folderDownloadItemsContainer) {
  // adds a listener that will check/uncheck all download checkboxes when the folder checkbox is checked/unchecked

  $folderCheckBox.on('change', function() {
    var $downloadCheckboxes = $folderDownloadItemsContainer.find('.js-download-item-checkbox');
    if ($folderCheckBox.is(':checked')) {
      $downloadCheckboxes.each(function() {
        var $this = $(this);
        if ($this.is(':not(:checked)')) $this.trigger('click');
      })
    } else {
      $downloadCheckboxes.each(function() {
        var $this = $(this);
        if ($this.is(':checked')) $this.trigger('click');
      })
    }
  });
}

function addElementRemover($deleteBtn, $parentElement, elementName){
  $deleteBtn.on('click', function(e) {
    e.preventDefault();

    if ($parentElement.hasClass('js-sortable-folder') && folderHasDownloads($parentElement)) {
      swal({
        title: "",
        text: "Can not delete a folder containing file(s)",
        icon: "warning"
      });
    } else {
      swal({
        title: "",
        text: `Are you sure you want to delete ${elementName}?`,
        icon: "warning",
        buttons: {
          cancel: {
            text: "Cancel",
            value: false,
            visible: true,
            className: "btn btn-dark",
            closeModal: true,
          },
          confirm: {
            text: "Delete",
            value: true,
            visible: true,
            className: "btn btn-primary",
            closeModal: true,
          }
        }
      })
      .then((value) => {
        if (value) {
          showLoadingMessage('Deleting file');
          $.ajax({
            url: $deleteBtn.attr('href'),
            method: 'delete',
            dataType: 'json',
            success: function(data) {
              hideLoadingMessage();
              if (data['success']) {
                if ($parentElement.hasClass('js-sortable-download-item')) cleanUpFileReferences($parentElement);
                $parentElement.remove();
                displayFlashMessage('success', data['message']);
              } else {
                displayFlashMessage('danger', data['message']);
              }
            }
          });
        }
      });
    }
  });
}

function cleanUpFileReferences($fileElement) {
  var $checkbox = $fileElement.find('.js-download-item-checkbox');
  var downloadID = $fileElement.data('download-id');
  var $versionFiles = $(`input[id*='download_id'][value=${downloadID}]`);

  if ($checkbox.is(':checked')) {
    // trigger listeners to remove file from queue (filesToAdd) & update cocoon counter ($('#js-add-files').data('count'))
    $checkbox.trigger('click');
  }

  /*
    We need to remove version files (product_version_downloads) associated with the deleted download
    so it doesn't get sent to the backend and cause an ActiveRecord::RecordNotFound error.
  */
  $versionFiles.each(function() {
    var $this = $(this);
    var $parentElement = $this.parent('div.nested-fields');
    $parentElement.remove();
  })
}

function folderHasDownloads($folder) {
  return $folder.find('.js-sortable-download-item').length ? true : false;
}

export function transferFileElementOutOfPond(pond, file, downloadHTML) {
  //newly uploaded files will be appended to a no-folder container

  var $downloadsContainer = $('#no-folder-downloads');
  var $downloadElement = $(downloadHTML);
  var $deleteBtn = $downloadElement.find('.js-delete-download');
  var fileName = $downloadElement.find('.js-file-name').text();

  addElementRemover($deleteBtn, $downloadElement, fileName);
  $downloadsContainer.append($downloadElement);

  //remove local FilePond instance of uploaded file
  if (pond) {
    pond.removeFile(file.id);
  }

  // preselect newly added download element
  $downloadElement.find('.js-download-item-checkbox').trigger('click');

  //scroll to the newly appended element
  $('#js-downloads-container').animate({ scrollTop: $downloadElement.offset().top }, 500);
}

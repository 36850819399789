$(document).on('turbolinks:load', function () {

  var $readAllSwitch = $('#js-read-all-switch'),
      sortAboutVal = $readAllSwitch.data('sort-about');

  $readAllSwitch.on('click', function(){
    var $this = $(this);
    if ($this.is(':checked')) {
      swal({
        title: `Are you sure you want to mark all ${sortAboutVal} messages as read?`,
        text: "",
        icon: "warning",
        buttons: {
          cancel: {
            text: "Cancel",
            value: false,
            visible: true,
            className: "btn btn-dark",
            closeModal: true,
          },
          confirm: {
            text: "Yes. Mark all as read!",
            value: true,
            visible: true,
            className: "btn btn-primary",
            closeModal: true
          }
        }
      })
      .then((value) => {
        if (value) {
          $('body').addClass('loading').prepend('<div class="loading-messages">' + `Marking all ${sortAboutVal} messages as read...` + '</div>');
          location.href = '/inbox/read_all/?q=' + sortAboutVal;
        } else {
          $this.prop('checked', false);
        }
      });
    };
  });
});


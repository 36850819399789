$(document).on('turbolinks:load', function () {

  // Categories section
  if($('.js-category-list').length) {
    // allow selection of 1 main category and up to 3 subcategories
    // hide sub level categories and display when main/parent is clicked

    var $categories = $('.js-category-input');
    var mainCategoryCount = 0;
    var maxMainCategory = 1;

    $categories.each(function() {

      var $this = $(this);
      var $subcategories = $this.closest('li').find('.js-subcategory-input');
      var subcategoryCount = 0;
      var maxSubcategory = 3;

      if($this.is(':checked')) {
        $this.closest('li').find('ul').addClass('show');
        disableUnselectedCheckboxes($categories);
        mainCategoryCount += 1;
      }

      $this.on('change',function(){

        if($this.is(':checked')) {
          $this.closest('li').find('ul').addClass('show');
          disableUnselectedCheckboxes($categories);
          mainCategoryCount += 1;
        } else {
          $this.closest('li').find('ul').removeClass('show');
          mainCategoryCount -= 1;
          $subcategories.each(function(){ $(this).prop({ 'checked': false, 'disabled': false }) });
          subcategoryCount = 0;
          // some existing products may already have more than the max allowed main category
          if (mainCategoryCount < maxMainCategory) {
            enableCheckboxes($categories);
          } else {
            $this.prop('disabled', true);
          }
        }
      });

      // Subcategories
      $subcategories.each(function(){

        var $this = $(this);

        if($this.is(':checked')) {
          subcategoryCount += 1;
          if(subcategoryCount >= maxSubcategory) {
            disableUnselectedCheckboxes($subcategories);
          }
        }

        $this.on('change',function(){
          if($this.is(':checked') ) {
            subcategoryCount += 1;
            if(subcategoryCount >= maxSubcategory) {
              disableUnselectedCheckboxes($subcategories);
            }
          } else {
            // some existing products might already have more than the max allowed subcategories
            if(subcategoryCount === maxSubcategory) {
              enableCheckboxes($subcategories);
            } else if (subcategoryCount > maxSubcategory) {
              $this.prop('disabled', true);
            }
            subcategoryCount -= 1;
          }
        });
      });
    });
  }
});

function disableUnselectedCheckboxes(checkboxList) {
  $(checkboxList).each(function() {
    var $this = $(this);
    if(!$this.is(':checked')) {
      $this.prop('disabled', true);
    }
  });
};

function enableCheckboxes(checkboxList) {
  $(checkboxList).each(function() {
    var $this = $(this);
    if($this.prop('disabled')) {
      $this.prop('disabled', false);
    }
  });
};

/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'core-js/stable';
import 'regenerator-runtime/runtime';

// Turbolinks
var Turbolinks = require("turbolinks");
Turbolinks.start();

// JS dependencies (npm/yarn)
import $ from 'jquery';
import "./jquery";
import 'jquery-ujs';
import 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-switch-button';
import 'summernote/dist/summernote-lite.min.js';
import 'cocoon-js';
import 'flatpickr';
import 'select2';
import 'select2/dist/css/select2.css';
import Tagify from '@yaireo/tagify';
import 'sweetalert';

// JS dependencies (other external)

import '../libraries/detect-swipe';
import '../libraries/featherlight';
import '../libraries/featherlight-gallery';
import '../libraries/jquery-bar-rating';

// Load SCSS
import '../stylesheets/application';

// Our Code

import './components/backend_menu/backend_menu';
import './components/backend_search/backend_search';
import './components/chart/chartkick';
import './components/chart/highcharts';
import './components/checkout/checkout';
import './components/input_date/input_date';
import './components/input_file/input_file';
import './components/input_password/show_password';
import './components/input_select/input_select';
import './components/input_select/input_user_select';
import './components/input_select/input_variant_file_select';
import './components/input_slug/input_slug';
import './components/input_text/input_text';
import './components/input_text/counted';
import './components/modal/modal_cart_login';
import './components/modal/modal_support';
import './components/modal/modal_youtube';
import './components/product/bar_rating';
import './components/product/product_content';
import './components/product/variant_pricing';
import './components/sort/sort';
import './components/swiper_init/swiper';
import './components/analytics/google_analytics';
import './components/copy/copy_text';
import './components/mailchimp/mailchimp_subscribe_form';

import './views/account/account';
import './views/admin/coupons/coupons';
import './views/admin/orders/orders';
import './views/admin/payouts/payouts';
import './views/admin/product_review/flags';
import './views/admin/refunds/refunds';
import './views/admin/sale_campaigns/sale_campaigns';

import './views/creator/affiliates/creator_affiliates';
import './views/creator/emails/emails';
import './views/creator/products/categories';
import './views/creator/products/commission';
import './views/creator/products/dev_fund';
import './views/creator/products/edit_product';
import './views/creator/products/form_sections';
import './views/creator/products/submit_review';
import './views/creator/products/variants_sortable';
import './views/creator/products/version_downloads_sortable';
import './views/creator/products/version_form';
import './views/creator/products/webhook_form';
import './views/creator/products/webhook_resend_form';

import './views/header/main_menu';
import './views/header/user_dropdown';
import './views/header/creator_dropdown';
import './views/inbox/read_all_switch';
import './views/login/login';
import './views/order/pickup';
import './views/privacy/privacy';

$(document).on('turbolinks:load', function(){

  // Boootsrap initialization
  $('.alert').alert();
  $('[data-bs-toggle="tooltip"]').tooltip();
  $('.toast').toast('show');

  // Tagify initialization
  var $tagInputs = $('.js-tags');
  if ($tagInputs.length > 0){
    $tagInputs.each(function(index, el){
      var $el = $(el);
      var taglist = $el.data('taglist') || [];

      var tagify = new Tagify(el, {
        placeholder: 'Tags ...',
        whitelist: taglist,
        delimiters: ','
      });
    });
  }

  // Tagify initialization for Webhook resend form
  var $orderItemIdInputs = $('.js-order-item-ids');
  if ($orderItemIdInputs.length > 0) {
    $orderItemIdInputs.each(function(index, el){
      new Tagify(el, {
        placeholder: 'Order item IDs ...',
        pattern: /^[0-9]*$/,
        delimiters: ','
      });
    });
  };

  // Featherlight initialization
  $('.js-product-gallery .swiper-slide a').featherlightGallery({
		previousIcon: '<',
		nextIcon: '>',
		galleryFadeIn: 300,
		openSpeed: 300
	});

  // Bootstrap switch button initialization
  $('input[data-bs-toggle="switchbutton"]').each(function(index, el){
    el.switchButton();
  });

  // currency input formatter
  $('.price-in-dollars').on('input', function() {
    let value = $(this).val();
    value = value.replace(/[^0-9.]/g, '');
    if (value.indexOf('.') !== -1) {
      value = value.split('.').slice(0, 2).join('.');
      value = value.replace(/(\..*)\./g, '$1');
    }
    $(this).val(value);
  });

  // disable turbolinks on anchor tags in the Cookiebot Dialog Box
  const observer = new MutationObserver(function(mutations) {
    mutations.forEach(function(mutation) {
      mutation.addedNodes.forEach(function(node) {
        if ($(node).is('#CybotCookiebotDialog')) {
          $('#CybotCookiebotDialogDetailBodyContentCookieContainerTypes').attr('data-turbolinks', 'false');
        };
      });
    });
  });

  const targetNode = document.body;
  const config = { childList: true, subtree: true };

  observer.observe(targetNode, config);

});

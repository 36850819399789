$(document).on('turbolinks:load', function(){
  var $payoutsForm = $('.js-payouts-form');

  if ($payoutsForm.length > 0) {

    var $payoutConfirm = $('.js-payouts-process_confirm');
    var $payoutBtn = $('.js-payouts-process_btn');

    $payoutConfirm.on('change', function() {
      if (this.checked) {
        $payoutBtn.prop('disabled', false);
      } else {
        $payoutBtn.prop('disabled', true);
      }
    });

    var $detailsToggle = $('.js-payouts-details_toggle');
    var $detailsForm = $('.js-payouts-details_form');
    var $detailsDisplay = $('.js-payouts-details_display');

    $detailsToggle.click(function(e){
      e.preventDefault();

      $('.chosen-container').each(function() {
        $(this).attr('style', 'width: 100%');
      });

      $detailsDisplay.hide();
      $detailsToggle.hide();
      $detailsForm.show();
    });


    var $markAsPaid = $('.js-payouts-markAsPaid');
    $markAsPaid.click(function(e){
      e.preventDefault();

      var $this = $(this);

      // hacky way to check if this button should hit the server backend - we still have backend checks
      // in case of weird behavior or if someone tries to circumvent this
      if ($this.text() == 'Mark as Paid') {
        if (confirm($this.data('js-confirm'))) {
          $this.addClass('disabled');
          $this.text('Marking ...');

          var postUrl = '/admin/user_payouts/' + $this.data('user-payout-id') + '/mark_as_paid';
          $.post({
            url: postUrl,
            dataType: 'json'
          }).then(function(response){
            if (response['success'] == true) {
              $this.text('Paid');
              $this.removeClass('btn-warning disabled').addClass('btn-success cursor-default');
              var $status = $this.closest('tr').find('.js-payout-userPayoutStatus');
              $status.empty();
              $status.html('<span class="badge bg-success"><i class="fas fa-check"></i></span> <strong>Paid</strong>');
            } else {
              $this.text('Error');
              $this.removeClass('btn-warning disabled').addClass('btn-danger cursor-default');
            }
          });
        }
      }

    });

  }
});

// Instructions:
//   - Add 'copy-text' id to the value you want written to the clipboard.
//   - Add the class 'js-copy-text' to any button you want to activate the copyText component.
//   - Set the 'data-copy-target' attribute on the button to the ID of the element containing the text to copy ie: id="copy-text".
// 
// Example:
// <p>
//     Hello World! Copy this link:
//     <a href="http://blendermarket.com/?ref=<%= @affiliate.id %>" id="copy-text">http://blendermarket.com/?ref=<%= @affiliate.id %></a>

//     <button class="btn btn-primary btn-sm js-copy-text" data-copy-target="copy-text">
//         <i class="fa fa-solid fa-copy"></i> Copy
//     </button>
// </p>
// 

function copyText(event) {
    event.preventDefault();
    var $btn = $(event.currentTarget);
    var textToCopyId = $btn.data('copy-target');
    var $textToCopyElement = $('#' + textToCopyId);
    var textToCopy = $textToCopyElement.attr('href') || $textToCopyElement.text() || $textToCopyElement.val();

    navigator.clipboard.writeText(textToCopy).then(function() {
        $btn.html('<i class=""></i> Copied');
        $btn.removeClass('btn-primary').addClass('btn-success');
        $btn.css('cursor', 'not-allowed');

        setTimeout(function() {
            $btn.html('<i class="fa fa-solid fa-copy"></i> Copy');
            $btn.removeClass('btn-success').addClass('btn-primary');
            $btn.css('cursor', 'pointer');
            $btn.prop('disabled', false);
        }, 1000);
    });
}

$(document).on('turbolinks:load', function() {
    $('.js-copy-text').on('click', copyText);
});

$(document).on('turbolinks:load', function(){

		var main      = '#ls'
		,	results     = '#ls--results'
		,	loader      = '#ls--loading'
		,	input  			= '#ls--input'
		,	helper      = '#ls--helper'
		, $spinner		= $('.js-backend-search-spinner')
		,	timer

		$(input).on('keyup keypress', function ( e ) {

			// clear the previous timer
			clearTimeout(timer)

			var key         = e.which
			,	that        = this
			,	val 				= $.trim( $(this).val() )
			,	valEqual    = val == $(that).val()
			,	notEmpty    = '' !== val
			, searchPath  = $(main).data('search-path')
			, basePath  	=	 $(main).data('base-path')

			// 600ms delay so we dont exectute excessively
			timer = setTimeout(function() {

				// don't proceed if the value is empty or not equal to itself
				if ( !valEqual && !notEmpty )
					return false;

				// if we have more than 1 character
				if ( val.length >= 1 || val.length >= 1 && 13 == key ) {

					// dont run on escape or arrow keys
					if( blacklistedKeys( key ) )
						return false;

					// show loader
					$spinner.show()

					// remove any helpers
					$( helper ).fadeOut().remove();

					// make the search request
				  $.ajax({
				    type:'GET',
				   	url: searchPath+'/?q='+encodeURIComponent(val),
				    dataType : 'script',
				    success: function(){
							$spinner.hide()
				    }
				  });

				} else if (val == '') {

					// show loader
					$spinner.show()

				  $.ajax({
				    type:'GET',
				   	url: basePath,
				    dataType : 'script',
				    success: function(){
							$spinner.hide()
				    }
				  });

				}

			}, 600);

		});

		/**
		*	Utility function to destroy the search
		*/
		function destroySearch(){

			$( input ).val('');
		}

		/**
		* 	Blacklisted keys - dont allow search on escape or arrow keys
		*/
		function blacklistedKeys( key ){

			return 27 == key || 37 == key || 38 == key || 39 == key || 40 == key;

		}
});

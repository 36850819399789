/*
*	Returns a list of params used for sorting/filtering
*/
export function url_add_param(url, param, value){

	var hash       = {};
	var parser     = document.createElement('a');

	parser.href    = url;

	var parameters = parser.search.split(/\?|&/);

	for(var i=0; i < parameters.length; i++) {
		if(!parameters[i])
			continue;

			var ary      = parameters[i].split('=');
			hash[ary[0]] = ary[1];
	}

	hash[param] = value;

	var list = [];
	Object.keys(hash).forEach(function (key) {
		list.push(key + '=' + hash[key]);
	});

	parser.search = '?' + list.join('&');
	return parser.href;
}

/*
*	Remove a specific param/value pair from a url
*/
export function url_remove_param(url, parameter) {
  //prefer to use l.search if you have a location/link object
  var urlparts= url.split('?');
  if (urlparts.length>=2) {

      var prefix= encodeURIComponent(parameter)+'=';
      var pars= urlparts[1].split(/[&;]/g);

      //reverse iteration as may be destructive
      for (var i= pars.length; i-- > 0;) {
          //idiom for string.startsWith
          if (pars[i].lastIndexOf(prefix, 0) !== -1) {
              pars.splice(i, 1);
          }
      }

      url= urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : "");
      return url;
  } else {
      return url;
  }
}

export function url_get_param(param) {
  var sPageURL = decodeURIComponent(window.location.search.substring(1)),
      sURLVariables = sPageURL.split('&'),
      sParameterName,
      i;

  for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');

      if (sParameterName[0] === param) {
          return sParameterName[1] === undefined ? true : sParameterName[1];
      }
  }
};

export function get_base_url() {
    var re = new RegExp(/^.*\//);
    return re.exec(window.location.href);
}

/*
*	Converts string of text into slug
*/
export function text_to_slug(Text){

    return Text
        .toLowerCase()
        .replace(/\.[^/.]+$/, "")
        .replace(/ /g,'-')
        .replace(/[^\w-]+/g,'')
        ;
}

/*
*	Takes this_string into This String
*/
export function humanize(Text) {
  return Text
      .replace(/^[\s_]+|[\s_]+$/g, '')
      .replace(/[_\s]+/g, ' ')
      .replace(/^[a-z]/, function(m) { return m.toUpperCase(); });
}

/*
*	Dollars to cents
*/
export function dollars_to_cents(amount){
	return amount * 100.00
}

/*
*  Displays loading message on the page
*/
export function showLoadingMessage(message) {
  $('body').addClass('loading').prepend('<div class="loading-messages">' + message + '...</div>');
  return;
}

/*
*  Removes loading message from the page
*/
export function hideLoadingMessage() {
  $('.loading-messages').remove();
  $('body').removeClass('loading');
  return;
}

/*
*  Displays flash notice similar to 'app/views/partials/notices'
*/
export function displayFlashMessage(type, msg) {
  // expects a type string argument that aligns with bootstrap bg colors
  var $notice = $(
    `<div class="fixed-bottom p-4" style="z-index: 1999;">
      <div class="toast bg-${type} show" role="alert">
        <div class="toast-body">
          ${msg}
        </div>
      </div>
    </div>`
  );

  $('body').prepend($notice);

  setTimeout(function(){
    $notice.remove();
  }, 8000);

  return;
}

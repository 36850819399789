$(document).on('turbolinks:load', function(){
  $(".show-password").each(function (index, input) {
    var $input = $(input);
    var $toggler = $('.toggle-show-password');

    $toggler.on('click', function () {
      var change = "";
      if ($(this).hasClass('fa-eye')) {
        $(this).removeClass('fa-eye').addClass('fa-eye-slash')
        change = "text";
      } else {
        $(this).removeClass('fa-eye-slash').addClass('fa-eye')
        change = "password";
      }
      var $rep = $("<input type='" + change + "' required />")
        .attr("id", $input.attr("id"))
        .attr("name", $input.attr("name"))
        .attr('class', $input.attr('class'))
        .val($input.val())
        .insertBefore($input);
      $input.remove();
      $input = $rep;
    }).insertAfter($input);
  });
});
$(document).on('turbolinks:load', function () {

  var $saleCampaignForm = $('.js-saleCampaign-form');

  if ($saleCampaignForm.length > 0) {
    var $heroTrigger = $('.js-saleCampaign-heroTrigger');
    var $heroFields = $('.js-saleCampaign-heroFields');

    var $popularTrigger = $('.js-saleCampaign-popularTrigger');
    var $popularFields = $('.js-saleCampaign-popularFields');

    $heroTrigger.on('change', function(){
      if (this.checked) {
        $heroFields.show();
      } else {
        $heroFields.hide();
      }
    });

    if (!$heroTrigger[0].checked) {
      $heroFields.hide();
    }

    $popularTrigger.on('change', function(){
      if (this.checked) {
        $popularFields.show();
      } else {
        $popularFields.hide();
      }
    });

    if (!$popularTrigger[0].checked) {
      $popularFields.hide();
    }
  }

});

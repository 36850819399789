$(document).on('turbolinks:load', function(){
  var $form = $('.js-affiliate-form');
  if ($form.length > 0) {
    var $productsToggle = $('.js-affiliate-productsToggle');
    var $productsTarget = $('.js-affiliate-productsTarget');

    var updateProductsTarget = function(){
      if ($productsToggle.prop('checked')) {
        $productsTarget.hide();
        // remove any existing products
        $productsTarget.find('.remove_fields').click();
      } else {
        $productsTarget.show();
      }
    }

    $productsToggle.on('change', updateProductsTarget);
    updateProductsTarget();
  }
});

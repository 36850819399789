import Swiper, { Keyboard, Navigation, Pagination } from 'swiper';

let mySwiper; 
let categorySwiper;

$(document).on('turbolinks:load', function(){

  // Configure Swiper to use modules
  Swiper.use([Keyboard, Navigation, Pagination]);
  
  // Swiper initialization
  mySwiper = new Swiper ('.swiper-container', {
    
    // Optional parameters
    direction: 'horizontal',
    loop: true,
    keyboard: {
      enabled: true,
      onlyInViewport: true,
    },

    pagination: {
      el: '.swiper-pagination',
    },
    navigation: {
      prevEl: '.swiper-button-prev' ,
      nextEl: '.swiper-button-next' ,
    },
    
  });

  // Initializes the swiper on category show pages
  categorySwiper = new Swiper ('.swiper-container-category', {
    direction: 'horizontal',
    keyboard: {
      enabled: true,
      onlyInViewport: true,
    },
    slidesPerView: 1.5, 
    spaceBetween: 15,
    breakpoints: {
      400: {
        slidesPerView: 3
      },
      768: {
        slidesPerView: 4,
        spaceBetween: 15
      },
      1024: {
        slidesPerView: 6,
        spaceBetween: 30,
      },
    },
    loop: false, // Enable loop mode
    navigation: {
      nextEl: '.swiper-button-next', // 
      prevEl: '.swiper-button-prev',
    },
  });
})

export { mySwiper, categorySwiper }
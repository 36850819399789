$(document).on('turbolinks:load', function () {

	var product_price = $('#product_price').attr('data-price') > 0 ? $('#product_price').attr('data-price') : 0
	,		dev_fund 			= ( $('#product_info_attributes_dev_fund').val() / 100 ).toFixed(2)
	,		setPrice 			= ( ($('.data-box').attr('data-box-processing-fees') * dev_fund) / 100).toFixed(2)

	$('#dev_fund_total .amount').text(setPrice);

  var $devFundSlider = $('.js-devFund-slider');
  if ($devFundSlider.length > 0 ){
    var $percentText = $('.js-devFund-percentText');
    var $amountText = $('.js-devFund-amountText');
    var $dataBoxText = $('.js-dataBox-devFundText');

    $devFundSlider.on('input', function(e){
      var value = $(this).val();

      var setPrice = $('.data-box').attr('data-box-processing-fees');
      var totalPrice = (setPrice / 100) * (value / 100);
      var totalPriceRounded = totalPrice.toFixed(2);

      $percentText.text(value);
      $amountText.text(totalPriceRounded);
      $dataBoxText.text("$" + totalPriceRounded);
      // TODO recalculate data box because we updated the dev fund slider
    });
  }

});

$(document).on('turbolinks:load', function () {

  // Reset YouTube video when modal is closed
  var $modal = $('.js-modal-youtube');
  if ($modal.length > 0) {
    // Store video source for autoplay and auto-stop
    var videoSrc = $('.yvideo').attr('src');

    $modal.on('show.bs.modal', function (e) {
      $('.yvideo').attr('src', videoSrc + "&autoplay=1");
    });

    $modal.on('hidden.bs.modal', function (e) {
      $('.yvideo').attr('src', videoSrc);
    });
  }

});

import { url_get_param, url_add_param, url_remove_param } from '../../util/methods';

$(document).on('turbolinks:load', function () {

  function isSortActive(){
    return $('.reset-sort').length
  }

  function isSearchActive(){
    return $('body').hasClass('layout-search-index')
  }

  function isInbox(){
    return $('body').hasClass('layout-inbox-index')
  }

  function removeSortParams(){
    var url = url_remove_param(location.href,'sort_sales')
    url = url_remove_param(url,'sort_date')
    url = url_remove_param(url,'sort_price')
    url = url_remove_param(url,'sort_range')
    url = url_remove_param(url,'sort_reviews')
    url = url_remove_param(url,'sort_about')
    url = url_remove_param(url,'sort_status')
    url = url_remove_param(url,'sort_earnings')
    url = url_remove_param(url,'sort_hearts')
    url = url_remove_param(url,'sort_paid')
    url = url_remove_param(url,'sort_amount')
    url = url_remove_param(url,'sort_items_amount')
    url = url_remove_param(url,'sort_claimed')
    url = url_remove_param(url,'sort_license')
    url = url_remove_param(url,'sort_role')
    url = url_remove_param(url,'sort_rating')
    url = url_remove_param(url,'sort_sale')
    url = url_remove_param(url,'sort_version')
    return url
  }

  function removeOrderParams(){
    var url = url_remove_param(location.href,'sort_price')
    url = url_remove_param(url,'sort_sales')
    url = url_remove_param(url,'sort_reviews')
    url = url_remove_param(url,'sort_date')
    return url
  }

  // When a product sort filter is toggled
	$('.js-sort-selectFilter').on('change', function() {

    var $this = $(this);
		var selected = $this.find('option:selected');
    var type = selected.attr('data-type');
    var value = selected.val();
    var url = location.href;

    if (type == 'reset') {
      url = removeSortParams();
    } else if ($this.hasClass('order-by') ) {
      url = removeOrderParams();
      url = url_remove_param(url,'page') //reset to first page whenever a new sort is applied
      url = url_add_param(url, type, value);
    } else if (isSearchActive()) {
      url = removeSortParams();
      url = url_add_param(url, type, value);
    } else if (isInbox()) {
      url = url_add_param(location.href, type, value);
    } else {
      url = url_add_param(location.href, type, value);
    }

		Turbolinks.visit(url);
		return false;
	});

  /*
  *	On page load, check for any params, highlight active filter dropdowns, and build active labels
  * @since 1.2.5
  */
  var supportedFilters   = [
                    ['sort_sales', url_get_param('sort_sales')],
                    ['sort_earnings', url_get_param('sort_earnings')],
                    ['sort_amount', url_get_param('sort_amount')],
                    ['sort_items_amount', url_get_param('sort_items_amount')],
                    ['sort_date', url_get_param('sort_date')],
                    ['sort_price', url_get_param('sort_price')],
                    ['sort_range', url_get_param('sort_range')],
                    ['sort_hearts', url_get_param('sort_hearts')],
                    ['sort_about', url_get_param('sort_about')],
                    ['sort_status', url_get_param('sort_status')],
                    ['sort_paid', url_get_param('sort_paid')],
                    ['sort_event_name', url_get_param('sort_event_name')],
                    ['sort_campaign', url_get_param('sort_campaign')],
                    ['sort_role', url_get_param('sort_role')],
                    ['sort_license', url_get_param('sort_license')],
                    ['sort_claimed', url_get_param('sort_claimed')],
                    ['sort_reviews', url_get_param('sort_reviews')],
                    ['sort_sale', url_get_param('sort_sale')],
                    ['on_sale', url_get_param('on_sale')],
                    ['sort_rating', url_get_param('sort_rating')],
                    ['sort_version', url_get_param('sort_version')]
									]

	$.each(supportedFilters, function(index, value){

		var sort_val = value[1]
		,	  sort_type = value[0]

    if (sort_val && window.location.search.indexOf(sort_type) > -1) {
      $('option[data-type="'+sort_type+'"][value="'+sort_val+'"]').prop('selected', true);
      buildFilterLabel(sort_type);
    }
	});

  // remove a filter tag and param
  $('div[data-filter-tag] i').click(function(e){
    e.preventDefault();

    var tag = $(this).closest('div');
    var type = tag.data('filter-type');
    var target = tag.data('filter-tag');

    tag.remove();
    var url = url_remove_param(location.href, target);
    url = url_remove_param(url,'page'); //reset to first page whenever a filter or sort is removed
    Turbolinks.visit(url);
  });

  // Build the label for the active sorting filter
  function buildFilterLabel(item){
    if( !$('#js-sort-tag-'+item).length ) {
      $('#js-sort-tags').append('<div data-filter-type="'+item+'" data-filter-tag="'+item+'" id="js-sort-tag-'+item+'" class="badge bg-primary me-2 fw-normal">'+item.split('_')[1]+'<i class="fas fa-times ms-1" role="button"></i></div>').addClass('has-filters');
    }
  }

  // Reset
  $('.js-sort-reset').on('click', function(e){
    e.preventDefault();
    url = removeSortParams()
    Turbolinks.visit(url)
  })

  $('.js-sort-linkFilter').each(function () {
    $(this).on('click', function (e) {
      e.preventDefault();
      var $this = $(this);

      var type = $this.attr('data-type');
      var value = $this.attr('data-value');

      if (type === 'sort_range') {
        var url = url_add_param(location.href, type, [$this.attr('data-value-min'), $this.attr('data-value-max')] );
      } else if (type === 'sort_hearts' && value === 'reset') {
        var url = url_remove_param(location.href, type)
      } else {
        var url = url_add_param(location.href, type, value);
      }

      url = url_remove_param(url,'page'); //reset to first page whenever a new filter is applied
      Turbolinks.visit(url);
      return false;
    });
  });
});

$(document).on('turbolinks:load', function () {
  
  var $checkBoxWrapper = $('.js-download-url-check-box');
  var $checkBox = $('#product_endpoint_responds_with_download_url');
  var $info = $('.js-download-url-info');

  $checkBoxWrapper.on('change', function () {
    if ($checkBox.is(':checked')) {
      $info.show();
    } else {
      $info.hide();
    };
  });

});
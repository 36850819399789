import { mySwiper } from '../swiper_init/swiper.js';

$(document).on('turbolinks:load', function () {

  var $supportModal = $(".js-support-modal");

  $supportModal.on('show.bs.modal', function() {
    mySwiper.keyboard.disable();
  });

  $supportModal.on('hide.bs.modal', function() { 
    mySwiper.keyboard.enable();
  });

});
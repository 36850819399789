$(document).on('turbolinks:load', function () {

  var $productContent;

  if ($('.layout-products-show').length) {
    $productContent = $('.js-product-content-show')
  } else if ($('.layout-products-edit').length) {
    $productContent = $('.note-editable');
  };

  if ($productContent != undefined && $productContent.length) {
    var $noneResponsiveIframes = $productContent.find('iframe').filter(function(){
      var $this = $(this);
      var src = $this.attr('src') || '';
      if ((src.includes('youtube') || src.includes('vimeo')) && $this.parent().not('div.ratio').length) {
        return $this;
      };
    });

    if ($noneResponsiveIframes.length) {
      $noneResponsiveIframes.each(function(){
        var $this = $(this);
        var $wrapper = $('<div>').addClass('ratio ratio-16x9');

        if ($this.parent('span.fr-video').length){
          var $span = $this.parent();
          $this.appendTo($wrapper);
          $span.replaceWith($wrapper);
        } else {
          $this.wrap($wrapper);
        };
      });
    };
  };
});

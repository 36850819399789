import Chartkick from "chartkick"
import Chart from "chart.js"

Chartkick.use(Chart);
Chartkick.configure({
  width: "100%",
  height:'250px',
  colors: ["#1dbdff","#697182","#55a626","#a920ae", "#c15c50", "#0082c8", "#f58231", "#911eb4", "#46f0f0", "#f032e6", "#d2f53c", "#fabebe", "#008080", "#e6beff", "#aa6e28", "#fffac8", "#800000", "#aaffc3", "#808000", "#ffd8b1"],
  curve:false,
  points:false,
  library:{
    scales:{
      xAxes:[{
        ticks: {
          beginAtZero: true,
          fontColor:'#818a9e'
        },
        gridLines:{
          display:false
        }
      }],
      yAxes: [{
        ticks: {
          fontColor:'#818a9e'
        },
        gridLines:{
          display:false
        }
      }]
    }
  }
});

import * as FilePond from 'filepond';

$(document).on('turbolinks:load', function () {

  var $editForm = $('.edit_product');
  if ($editForm.length > 0) {
    checkProductFormCompletion();

    $editForm.on('change', 'input', function(e){
      checkProductFormCompletion();
    });
  }

});

export function checkProductFormCompletion(){
  // 1. PRIMARY INFO
  updateFormCircle('.js-product-circle_primary', productFormPrimaryInfoComplete);

  // 2. CATEGORIES
  updateFormCircle('.js-product-circle_categories', productFormCategoriesComplete);

  // 3. PRODUCT PAGE (CONTENT)
  updateFormCircle('.js-product-circle_page', productFormPageComplete);

  // 4. GALLERY
  updateFormCircle('.js-product-circle_gallery', productFormGalleryComplete);

  // 5. FILES
  updateFormCircle('.js-product-circle_files', productFormFilesComplete);

  // 6. VARIANTS
  updateFormCircle('.js-product-circle_variations', productFormVariantsComplete);

  // 7. DOCUMENTATION
  updateFormCircle('.js-product-circle_documentation', productFormDocumentationComplete);

  // 8. COLLABORATORS
  updateFormCircle('.js-product-circle_collaborators', productFormCollaboratorsComplete);
}

function updateFormCircle(circleSelector, methodToCheck){
  var $circle = $(circleSelector);
  if (methodToCheck()){
    $circle.removeClass('far').addClass('fas');
  } else {
    $circle.removeClass('fas').addClass('far');
  }
}

function productFormPrimaryInfoComplete(){
  var title = $.trim($('.js-product-title').val());
  var slug = $.trim($('.js-product-slug').val());
  var price = $('.js-product-price').val();

  if (title && title.length > 0 && slug && slug.length > 0 && price && price >= 0) {
    return true;
  }
  return false;
}

function productFormCategoriesComplete(){
  var categoriesCount = $('.product_categories input:checked').length;
  var tagsValue = $('#product_tags').val();
  var versionsCount = $('.product_info_software_versions input:checked').length;
  var licensesCount = $('.product_info_license input:checked').length;

  if (categoriesCount > 0 && tagsValue && versionsCount > 0 && licensesCount > 0) {
    return true;
  }

  return false;
}

function productFormPageComplete(){
  var contentText = $('.js-product-content').summernote('code');
  if ($.trim(contentText).length > 0) {
    return true;
  }

  return false;
}

function productFormGalleryComplete(){
  var $imageInputFeatured = $('.js-imageInputFeatured');
  var $imageInputMulti = $('.js-imageInputMulti');
  var pond = FilePond.find($imageInputFeatured[0]);

  var dataFilesFeatured = $imageInputFeatured.data('files');
  var dataFilesMulti = $imageInputMulti.data('files');

  if (pond != null) {
    var files = pond.getFiles();
    if (files && files.length > 0) {
      return true;
    }
  }
  else if (pond == null && dataFilesFeatured && dataFilesMulti && dataFilesFeatured.length + dataFilesMulti.length > 4) {
    // FP may not be initialized yet, so check the data we give to it
    return true;
  }

  return false;
}

function productFormFilesComplete(){
  // First check if we have previously uploaded files
  var hasDownloads = $('.js-download-wrapper').length || $('.js-download-item').length;
  if (hasDownloads) {
    return true;
  } else {
    // check if we have recently uploaded files
    var pond = FilePond.find($('.js-fileInputDownload')[0]);
    if (pond != null) {
      var files = pond.getFiles();
      if (files && files.length > 0) {
        return true;
      }
    }
  }
  return false;
}

function productFormVariantsComplete(){
  var $variants = $('.product-variable-pricing .variant-nested-field');
  if ($variants.length == 0) { return true; }

  var validatedCount = 0;
  for (var i=0; i<$variants.length; i++){
    if (validateProductVariant($variants[i])){
      validatedCount++;
    }
  }

  if (validatedCount == $variants.length) {
    return true;
  }

  return false;
}

function validateProductVariant(container){
  var $container = $(container);
  var title = $container.find('.js-productVariant-title').val();
  var price = parseFloat($container.find('.js-productVariant-price').val());

  return (title != null && title.length > 0 && price >= 0);
}

function productFormDocumentationComplete(){
  var docsText = $('.js-product-documentation').summernote('code');
  if ($.trim(docsText).length > 0) {
    return true;
  }

  return false;
}

function productFormCollaboratorsComplete(){
  // TODO not sure what to validate, maybe collaborator amounts but we have
  // other handling for that on page load
  return true;
}

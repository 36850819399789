$(document).on('turbolinks:load',function(){

  // Checkbox Consents
	$('.js-consent-toggle').each(function(){

		$(this).on('change',function(e){

			e.preventDefault();

      formData = JSON.stringify($(this).closest('.checkbox-item-wrap').html().trim())

		  if( $(this).is(':checked') ){

		 	  data = {consent: {policy_id: $(this).data('policy-id'), form: formData }}

		    $.ajax({
			    type:'POST',
			   	url: '/account/privacy-center/consents',
			   	data: data,
			   	dataType : 'script'
			  });

		  } else {

		    $.ajax({
			    method:'PATCH',
			   	url: '/account/privacy-center/consents/'+$(this).attr('id'),
          data:{consent:{accepted:[false], form: formData }},
			   	dataType : 'script'
			  });

		  }

		});

	});

  // User Data Export
  $('#js-user-data-export').on('click', function(){
    $.ajax({
      method:'PATCH',
      url: '/account/privacy-center/data/'+$(this).data('export-id'),
      dataType : 'script'
    });
  });

});

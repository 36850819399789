// This file is responsible for sorting files and folders in the Product Version form's Product Files modal
// We are making folders sortable/draggable within the .js-download-folders-container div
// We are also making downloads/files sortable inside the .js-download-items-container divs (with the ability to drag from one container to another)
// Sorting functionality is disabled by default
// Once sorting is enabled, we are making use of javascript's MutationObserver to detect and subscribe newly added folder and file nodes to sorting functionality

import Sortable from 'sortablejs';
import { displayFlashMessage, showLoadingMessage, hideLoadingMessage } from '../../../util/methods';

$(document).on('turbolinks:load', function() {

  var $downloadsMainContainer = $('#js-downloads-container');

  if ($downloadsMainContainer.length) {
    var $sortFilesBtn = $('#js-sort-files-btn');
    var $saveSortedFilesBtn = $('#js-save-sorted-files');
    var observerConfig = { childList: true }; // for MutationObserver
    var sortableFolderClassName = '.js-sortable-folder';
    var sortableDownloadClassName = '.js-sortable-download-item';
    var sortableHandleClassName = '.sortable-handle';
    var sortingActive = false;
    var newFolderObserver;
    var newDownloadObserver;

    $sortFilesBtn.on('click', function(e) {
      e.preventDefault();
      enableSorting();
      sortingActive = true;
    });

    $saveSortedFilesBtn.on('click', function(e) {
      // save sort positions and disable sorting

      e.preventDefault();
      var $folders = $downloadsMainContainer.find(sortableFolderClassName);
      var $noFolderDownloads = $('#no-folder-downloads').find(sortableDownloadClassName);
      showLoadingMessage('Saving sorted files');

      // set data
      var data = {
        download_folders_attributes: [],
        downloads_attributes: []
      };

      $folders.each(function(index, el) {
        var $this = $(el);
        var $downloads = $this.find(sortableDownloadClassName);
        var folderAttributes = {
          id: $this.attr('data-folder-id'),
          position: index
        };
        data.download_folders_attributes.push(folderAttributes);

        $downloads.each(function(index, el) {
          var $this = $(el);
          var downloadAttributes = {
            id: $this.attr('data-download-id'),
            position: index,
            download_folder_id: $this.attr('data-folder-id')
          };
          data.downloads_attributes.push(downloadAttributes);
        });
      });

      $noFolderDownloads.each(function(index, el) {
        var $this = $(el);
        var downloadAttributes = {
          id: $this.attr('data-download-id'),
          position: index,
          download_folder_id: null
        };
        data.downloads_attributes.push(downloadAttributes);
      });

      $.ajax({
        url: $(e.target).attr('href'),
        method: 'post',
        dataType: 'json',
        data: {
          _method: 'patch',
          product: data
        },
        success: function(response) {
          hideLoadingMessage();
          if (response['success']) {
            disableSorting();
            displayFlashMessage('success', 'Successfully saved sorted files!');
            sortingActive = false;
          } else {
            displayFlashMessage('danger', 'Failed to save sorted files!');
          }
        }
      });
    });

    // this gets run when user navigates away from the form
    // we need to remove sorting-related javascript before caching
    $(document).one('turbolinks:before-cache', function() {
      if (sortingActive) {
        disableSorting();
      }
    })

    function enableSorting() {
      var $sortableFoldersContainer = $('.js-download-folders-container');
      var $sortableDownloadsContainers = $('.js-download-items-container');

      // make folders sortable
      var sortableFolders = createSortable($sortableFoldersContainer[0], sortableFolderClassName, sortableHandleClassName);

      // create an observer for newly appended folder, to subscribe them to sorting
      newFolderObserver = createAppendObserver(sortableFolders);
      newFolderObserver.observe($sortableFoldersContainer[0], observerConfig);

      // make downloads in and out of folders sortable
      $sortableDownloadsContainers.each(function() {
        var $this = $(this);
        if ($this.attr('id') === 'no-folder-downloads') {
          // add sortability to downloads that do not belong to a folder
          var noFolderSortableDownloads = createSortable($this[0], sortableDownloadClassName, sortableHandleClassName, 'download-items');

          // create an observer for newly appended download/file element to '#no-folder-downloads' container, to subscribe them to sorting
          newDownloadObserver = createAppendObserver(noFolderSortableDownloads);
          newDownloadObserver.observe($this[0], observerConfig);
        } else {
          // add sortability to downloads in folders
          createSortable($this[0], sortableDownloadClassName, sortableHandleClassName, 'download-items');
        }
      });

      // show draggable icons
      $(sortableHandleClassName).each(function() {
        $(this).show();
      });

      // disable delete buttons
      $('.js-delete-btn').each(function() {
        $(this).hide();
      });

      // toggle buttons
      $sortFilesBtn.hide();
      $saveSortedFilesBtn.show();
    }

    function disableSorting() {
      // disable sorting
      destroySortable($('.js-download-folders-container')[0]);
      $('.js-download-items-container').each(function() {
        destroySortable(this);
      });

      //disconnect observers
      newFolderObserver = newFolderObserver.disconnect(); // disconnect() returns undefined
      newDownloadObserver = newDownloadObserver.disconnect();

      $(sortableHandleClassName).each(function() {
        $(this).hide();
      });

      $('.js-delete-btn').each(function() {
        $(this).show();
      })

      $saveSortedFilesBtn.hide();
      $sortFilesBtn.show();
    }
  }
});

function createAppendObserver(sortable) {
  // creates an observer that will subscribe newly appended folder or file nodes to sorting functionality
  return new MutationObserver(function(mutationsList, observer) {
    for (const mutation of mutationsList) {
      if (mutation.type === 'childList') {
        var $addedElement = $(mutation.addedNodes);
        var $sortableHandle = $addedElement.find('.sortable-handle');
        var $deleteBtn = $addedElement.find('.js-delete-btn');
        $sortableHandle.show();
        $deleteBtn.hide();
        // if added node is a folder, initialize file container sortable
        if ($addedElement.hasClass('js-sortable-folder')) {
          var $sortableDownloadsContainer = $addedElement.find('.js-download-items-container');
          createSortable($sortableDownloadsContainer[0], '.js-sortable-download-item', '.sortable-handle', 'download-items');
        }
        sortable.option('refresh');
      }
    }
  });
}

function createSortable(sortableContainer, draggableElementClassName, sortableHandleClassName, groupName) {
  // enables sortability of specified elements within a given container node
  return Sortable.create(sortableContainer, {
    draggable: draggableElementClassName,
    handle: sortableHandleClassName,
    chosenClass: 'chosen-draggable',
    ghostClass: 'ghost-draggable',
    group: groupName,
    onEnd: function(evt) {
      var $draggedItem = $(evt.item);  // dragged HTMLElement
      // if dragged from a different container (item is a download), update folder id
      if (evt.to !== evt.from) {
        var newFolderID = $(evt.to).parent().attr('data-folder-id') || '';
        $draggedItem.attr('data-folder-id', newFolderID);
      }
    }
  });
}

function destroySortable(sortableContainer) {
  var sortable = Sortable.get(sortableContainer);
  if (sortable) sortable.destroy();
}

$(document).on('turbolinks:load', function() {
  if ($(".email-subscribe-form").length) {
    const $input = $(".email-subscribe-form input[type='email']");
    const element = $input.get(0);

    $(".email-subscribe-form form").on("submit", function(event) {
      const emailFormat = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$/;

      if (!emailFormat.test($input.val())) {
        event.preventDefault();
        element.setCustomValidity("Please enter a valid email address in the format of user@example.com.");
        element.reportValidity();
      } else {
        element.setCustomValidity("");
      }
    });

    $input.on("input", function() {
      element.setCustomValidity("");
      element.reportValidity();
    });
  };
});

$(document).on('turbolinks:load', function() {

  var $variantFormsContainer = $('#js-sortable-variants');
  var productId = $('input[name="data_product_id"]').val();

  if ($variantFormsContainer.length) {
    
    var $variantNestedFields = $('.js-variant-nested-field');

    if ($variantNestedFields.length) {
      $variantNestedFields.each(function() {
        var $this = $(this);
        var $variantFileSelect = $this.find('.js-variant-file-select');

        // initialize file select dropdown for existing variant files
        if ($variantFileSelect.length) {
          $variantFileSelect.each(function() {
            initializeVariantFileSelect(this);
          });
        }

        // initialize newly added file select dropdown
        $this.on('cocoon:after-insert', function(e, $insertedItem) {
          if ($insertedItem.hasClass('js-variant-file-wrapper')) {
            let $insertedVariantFileSelect = $insertedItem.find('.js-variant-file-select');

            $insertedVariantFileSelect.each(function() {
              initializeVariantFileSelect(this);
            });
          }
        });
      });
    }

    // Initialize file select dropdown for newly added Variant nested form fields
    $variantFormsContainer.on('cocoon:after-insert', function(e, $insertedItem) {

      if ($insertedItem.hasClass('js-variant-nested-field')) {

        $insertedItem.on('cocoon:after-insert', function(e, $insertedItem) {
          
          if ($insertedItem.hasClass('js-variant-file-wrapper')) {
            let $insertedVariantFileSelect = $insertedItem.find('.js-variant-file-select');

            $insertedVariantFileSelect.each(function() {
              initializeVariantFileSelect(this);
            });
          }
        });
      }
    });

    function initializeVariantFileSelect(fileSelect) {
      let $fileSelect = $(fileSelect);

      $fileSelect.select2({
        width: '100%',
        ajax: {
          url: '/api/v1/products/files/search/' + productId,
          dataType: 'json',
          delay: 250,
          data: function(params) {
            return { q: params.term }
          },
          processResults: function(data) {
            return { results: data }
          },
          cache: true
        },
        placeholder: 'Select or Search by filename...'
      });
      
      $fileSelect.on('select2:select', function(e) {
        let data = e.params.data;
        let selected = new Option(data.text, data.id, true, true);
        $fileSelect.empty().append(selected).trigger('change');
      });
    };
  };
});
import { url_get_param, url_add_param, url_remove_param } from '../../util/methods';

$(document).on('turbolinks:load', function(){
  // Flatpickr initialization
  initializeFlatpickr();
});

function initializeFlatpickr(){
  $('.js-datepicker').flatpickr();
  $('.js-datetimepicker').flatpickr({
    enableTime: true,
  });

  var startDate = $('#dp-start').val()
  var endDate = $('#dp-end').val()

  $('#dp-start').flatpickr(
    {
      minDate: '2014-06-10',
      maxDate: $('#dp-end').data('end-date'),
      onChange: function (selectedDates, dateStr, instance) {
        if (selectedDates[0] > Date.parse(endDate) ) {
          alert('The start date can not be greater than the end date');
        } else {
          var url = url_add_param(location.href, 'start_date', dateStr);
          if( !url_get_param('end_date') ) {
            var url = url_add_param(url, 'end_date', endDate)
          }
          Turbolinks.visit(url)
        }
      }
    }
  );

  $('#dp-end').flatpickr(
    {
      minDate: '2014-06-10',
      maxDate: $('#dp-end').data('end-date'),
      onChange: function (selectedDates, dateStr, instance) {
        if (selectedDates[0] < Date.parse(startDate) ) {
          alert('The end date can not be less than the start date');
        } else {
          var url = url_add_param(location.href, 'end_date', dateStr);
          if( !url_get_param('start_date') ) {
            var url = url_add_param(url, 'start_date', startDate)
          }
          Turbolinks.visit(url)
        }
      }
    }
  );

  $('#subscription_expiration').flatpickr()
}

$(document).on('turbolinks:load', function (){
  var placeholderText = 'Search...'; 
	cleanupLastLoad();

  $('.js-select-multi').select2({
    multiple: true,
    width: '100%'
  });

  function initializeSelect2(selectElement) {
    var $selectElement = $(selectElement);
    if ($selectElement.data('url')) {
        $selectElement.select2({
        ajax: {
            url: $selectElement.data('url'),
            dataType: 'json',
            delay: 250,
            data: function (params) {
            return {
                q: params.term // search term
            };
            },
            processResults: function (data) {
            return {
                results: data.results
            };
            },
            cache: true
        },
        minimumInputLength: 3,
        placeholder: placeholderText,
        width: '100%'
        });        
    } else {
      $selectElement.select2({
        width: '100%'
      });        
    }
  }
  // Initialize for static page load
  $('.js-select, .js-input-select').each(function() {
  	initializeSelect2(this);
  });

  // Reinitialize for dynamically added elements (e.g., via Cocoon)
  $(document).on('cocoon:after-insert', function(e, insertedItem) {
    $(insertedItem).find('.js-select, .js-input-select').each(function() {
      initializeSelect2(this);
    });
  });

	function cleanupLastLoad(){
		// Select2 cleanup
		var $selects = $('.js-select');
		for (var i=0; i<$selects.length; i++){
			var $sel = $($selects[i]);
			if ($sel.data('select2')) $sel.select2('destroy');
		}
	
		var $multiSelects = $('.js-select');
		for (var i=0; i<$multiSelects.length; i++){
			var $sel = $($multiSelects[i]);
			if ($sel.data('select2')) $sel.select2('destroy');
		}
		
	}

});

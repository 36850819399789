$(document).on('turbolinks:load', function () {

  var $resend_form = $('.resend');
  
  if ($resend_form.length) {
    $resend_form.on('submit', function() {
      var text = 'Sending webhook';
      $('body').addClass('loading').prepend('<div class="loading-messages">' + text + '...</div>');
    });
  };

});
import Highcharts from 'highcharts'

$(document).on('ready turbolinks:load', function(){

  Highcharts.setOptions({
    colors: ['#0070F3', '#9747FF', '#FF47CC', '#F0D800', '#FD4F01', '#00F37E'],
    credits: { enabled: false },
    plotOptions: {
      line: {
        marker: {
          symbol: 'circle'
        }
      }
    }
  })

  var $charts = $('.js-chart')
  $charts.each(function(index, el){
    var $el = $(el)
    if ($el.data('path')){
      $.ajax({
        url: $el.data('path'),
        success: function(data, status){
          Highcharts.chart(el, data)
        },
        error: function(request, errorMessage){
          $el.empty()
          var $error = $('<div/>', { class: 'text-center text-danger lead mb-3' })
          var $icon = $('<i/>', { class: 'fa fa-exclamation-triangle fa-2x mt-4 mb-3 d-block' })
          $error.append($icon)
          $error.append('Sorry, there was an error while trying to load the chart.')
          $el.append($error)
        }
      })
    }
  })

})

$(document).on('turbolinks:load',function(){

  var $couponForm = $('.js-coupon-form');
  if ($couponForm.length > 0){

    // COUPON FORM SETUP

    var val = $('#coupon_coupon_type').val();
    toggleCouponType(val);
  	$('#coupon_coupon_type').on('change', function(){
  		toggleCouponType($(this).val());
  	});

    function toggleCouponType(val){
      if ( val == 'flat' ) {
        $('.coupon_discount_flat').show();
        $('.coupon_discount_percent').hide();
      } else {
        $('.coupon_discount_flat').hide();
        $('.coupon_discount_percent').show();
      }
    }

    // PRODUCT VARIANT SELECT SETUP
    // TODO merge with components/input_select/input_product_select.js

    $couponForm.on('cocoon:after-insert', function(){
      var $productSelects = $('.js-product-select');
      $.each($productSelects, function(i, el){
        $(el).attr('data-number', i); // set indexes for updating variant selects
      });
      $('.js-select').select2({ width: '100%' }); // re-initialize select2
    });

    $(document).on('change', '.js-product-select', function(){
      var $this = $(this);
      var val = $this.find('option:selected').val();
      var inputNumber = $this.data('number');
      $.ajax({
        type:'GET',
        url: '/product_variants?product=' + val + '&input_number=' + inputNumber,
        dataType : 'script'
      });
    });

    // INCLUDE ALL PRODUCTS SETUP
    var $productsToggle = $('.js-coupon-productsToggle');
    var $productsTarget = $('.js-coupon-productsTarget');

    var updateProductsTarget = function(){
      if ($productsToggle.prop('checked')) {
        $productsTarget.hide();
        // remove any existing products
        $productsTarget.find('.remove_fields').click();
      } else {
        $productsTarget.show();
      }
    }

    $productsToggle.on('change', updateProductsTarget);
    updateProductsTarget();

    // AFFILIATE COUPON SETUP
    var $affiliateToogle = $('.js-coupon-affiliate-toogle');
    var $affiliateTarget = $('.js-coupon-affiliate-target');
    var $affiliateCardHeader = $affiliateTarget.prev('div.card-header');

    var updateAffiliateTarget = function(){
      if ($affiliateToogle.prop('checked')) {
        $affiliateTarget.show();
        $affiliateCardHeader.removeClass('border-bottom-0');

        // cocoon nested creator_affiliate_coupon field
        var $nestedAffiliateCoupon = $affiliateTarget.find('div.nested-fields');
        if (!$nestedAffiliateCoupon.length) {
          // no nested field exists, so we add one
          $('.js-coupon-add-nested-affiliate-field').trigger('click');
        } else {
          // there is an existing one
          // because this is a has_one association we can't send multiple to the controller
          // we just have to modify the values of the existing one that may have been marked _destroy: true
          $nestedAffiliateCoupon.show();
          var $destroyAffiliateCoupon = $nestedAffiliateCoupon.find('input[id*="_destroy"]');

          if ($destroyAffiliateCoupon.val() === '1') {
            $destroyAffiliateCoupon.val('false');
            $nestedAffiliateCoupon.find('select.js-coupon-affiliate-select').val(null).trigger('change');
          }
        }

      } else {
        // remove cocoon nested creator_affiliate_coupon field
        if ($affiliateTarget.find('div.nested-fields').length) {
          $('.js-coupon-remove-nested-affiliate-field').trigger('click');
        }
        $affiliateTarget.hide();
        $affiliateCardHeader.addClass('border-bottom-0');
      }
    }

    $affiliateToogle.on('change', updateAffiliateTarget);
    updateAffiliateTarget();
  }

});

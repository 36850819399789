$(document).on('turbolinks:load', function () {

  var $submit = $('.js-creator-emailSendSubmit');
  var $sendToAllCustomers = $('.js-send-to-all-customers-checkbox');
  var $hintText = $('.js-send-to-all-hint');
  var $productNestedFields = $('.js-email-product-nested-fields');
  var $productSelect = $('.js-email-product-select');
  var $variantSelect = $('.js-email-product-variant-select');

  if ($submit.length > 0) {
    var originalEmailSendText = $submit.text();
    var $sendInput = $('.js-creator-emailSendInput');
    $('.js-creator-emailSendSwitch').on('change', function(){
  		if( $(this).is(':checked') ) {
  			$submit.removeClass('btn-primary').addClass('btn-danger').text('Send Email to Customers');
        $sendInput.val('true');
  		} else {
  			$submit.removeClass('btn-danger').addClass('btn-primary').text(originalEmailSendText);
        $sendInput.val('false');
  		}
  	});
  }

  $sendToAllCustomers.is(':checked') ? hideEmailProductNestedFields() : showEmailProductNestedFields();

  $sendToAllCustomers.on('change', function(){
    let $this = $(this);
    $this.is(':checked') ? hideEmailProductNestedFields() : showEmailProductNestedFields();
  });

  $productSelect.on('change', function(){
    let $this = $(this);
    let val = $this.find('option:selected').val();

    if (val !== ''){
      getProductVariants(val);
    }
  });

  function getProductVariants(id) {
    $.ajax({
      type:'GET',
      url: '/api/v1/product_variants?product=' + id,
      dataType: 'json',
      success: function(response){
        setupVariantSelectDropdown(response)
      }
    });
  };

  function setupVariantSelectDropdown(variants) {
    if (variants.length) {
      // enable dropdown and preselect 'All Variants' (no variant value)
      $variantSelect.prop('disabled', false).empty().append(new Option('All Variants', '', true, true));

      variants.forEach(function(variant){
        $variantSelect.append(new Option(variant.title, variant.id, false, false));
      });
    } else {
      // remove existing variant options and display placeholder
      $variantSelect.empty().append(new Option('No variations available', '', true, true));
    }
  }

  function hideEmailProductNestedFields() {
    $productNestedFields.removeClass('show');
    $hintText.text('This email will be sent to your entire customer base.');
    resetProductSelect();
  }

  function showEmailProductNestedFields() {
    $productNestedFields.addClass('show');
    $hintText.text('Selecting this will send the email to your entire customer base.');
  }

  function resetProductSelect() {
    $productSelect.val('').trigger('change');
    setupVariantSelectDropdown([]);
  }
});

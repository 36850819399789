import Sortable from 'sortablejs';

$(document).on('turbolinks:load', function () {

  var $variantContainer = $('#js-sortable-variants');

  if ($variantContainer.length){
    var sortable = new Sortable($variantContainer[0], {
      draggable: '.js-variant-nested-field',
      handle: '.draggable-handle',
      onSort: function(e) {
        updatePositions($('.js-variant-nested-field'));
      }
    });

    $variantContainer.on('cocoon:after-insert', function(e, $insertedItem){
      if ($insertedItem.hasClass('js-variant-nested-field')) {
        setUpCollapsibleForm($insertedItem)
        updatePositions($('.js-variant-nested-field'));
        $('.price-in-dollars').maskMoney({ allowZero: true }); // MaskMoney initialization
      };
    }).on('cocoon:after-remove', function(e, $removedItem){
      if ($removedItem.hasClass('js-variant-nested-field')) {
        updatePositions($('.js-variant-nested-field'));
      };
    });
  };

});

function setUpCollapsibleForm($insertedItem) {
  var $collapsible = $insertedItem.find('.js-variant-collapse');
  var $collapseToggler = $insertedItem.find('.js-collapse-toggler');
  var $id = $insertedItem.find('input.js-productVariant-title').attr('id').replace('title', 'collapse');
  $collapsible.attr('id', $id).addClass('show');
  $collapseToggler.attr({ href: `#${$id}`, 'aria-expanded': true })
};

function updatePositions($object) {
  $object.each(function(index, el){
    var $el = $(el);
    var $positionInput = $el.find("input[name*='position']");
    $positionInput.val(index + 1);
  });
};

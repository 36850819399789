$(document).on('turbolinks:load', function () {
    var $createBtn  = $('#js-create-account-btn'),
        $loginForm  = $('#js-login-form'),
        $loginLink  = $('#js-login-link'),
        $signUpForm = $('#js-registration-form');

    $createBtn.on('click', function(){
      $loginForm.hide();
      $signUpForm.show();
    });

    $loginLink.on('click', function(e){
      e.preventDefault();
      $signUpForm.hide();
      $loginForm.show();
    });

});
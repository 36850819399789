$(document).on('turbolinks:load', function () {

  var $refundForm = $('.js-adminRefund-form');

  if ($refundForm.length > 0) {

    var $checkboxes = $refundForm.find('.js-adminRefund-product');
    var $status = $refundForm.find('.js-adminRefund-status');

    $status.on('change', function(e){
      if ($(this).val() == 'refunded') {
        $checkboxes.prop('checked', 'true');
      }
    });

  }

  $refundForm.on('submit', function() {
    $('body').addClass('loading').prepend('<div class="loading-messages">Processing Refund ...</div>');
  })

});

$(document).on('turbolinks:load', function(){

  var $slugInputs = $('.js-input-slug');
  if ($slugInputs.length > 0) {
    $slugInputs.on('keypress', preventInvalidSlugInput);
    $slugInputs.on('paste', preventPasteSlugInput);
    $slugInputs.on('change', lowercaseSlugValue);

    $slugInputs.each(function(index, item) {
      var $item = $(item);
      var checkApiToUse = $item.data('check');

      if (checkApiToUse == "users") {
        checkSlug($item, '/api/v1/users/checkslug/');
      } else if (checkApiToUse == "products") {
        checkSlug($item, '/api/v1/checkslug/');
      }
    });

  }

  var $couponCodeInput = $('.js-input-coupon_code');
  if ($couponCodeInput.length > 0) {
    $couponCodeInput.on('keypress', preventInvalidSlugInput);
    $couponCodeInput.on('paste', preventPasteSlugInput);
    $couponCodeInput.on('change', lowercaseSlugValue);
  }

})

function preventInvalidSlugInput(e) {
  // Prevent user from entering characters not supported in slugs
  if (
    e.which == 32 ||
    (e.which < 48 && e.which !== 45 ) ||
    (e.which > 57 && e.which < 65) ||
    (e.which > 90 && e.which < 97) ||
    e.which > 122
  ) {
    return false;
  }
}

function preventPasteSlugInput(e) {
  e.preventDefault();
}

function lowercaseSlugValue(e) {
  var $this = $(this);
  $this.val($this.val().toLowerCase());
}

function checkSlug($el, path) {
  var slugTimer;

  $el.on('input', function(e){
    clearTimeout(slugTimer);

    var $this = $(this);
    var slug = stringToSlug($.trim($this.val().toLowerCase()));

    clearSlugFeedback($this);

    slugTimer = setTimeout(function(){
      // See if we should check the slug
      if (slug == '' || slug.length <= 5) {
        return;
      }

      $this.addClass('is-loading');

      $.ajax({
        url: path + slug,
        success: function(response){
          if (response && response['slug_taken'] == false) {
            slugSuccess($this, response);
          }
          else if (response && response['slug_taken'] == true && response['user_id'] == $this.data('user')) {
            slugSuccess($this, response);
          }
          else {
            slugFail($this, response);
          }
        }
      }); // end ajax
    }, 600); // end slugTimer = setTimeout(...);
  }); // end $el.on('keypress');
}

function clearSlugFeedback($target){
  $target.removeClass('is-loading is-valid is-invalid');
  $target.closest('.form-group').find('label.text-danger').remove();
}

function slugFail($target, response){
  clearSlugFeedback($target);
  $target.addClass('is-invalid');
  $target.after('<label class="text-danger small mb-2">Slug has been taken.</label>');
}

function slugSuccess($target, response){
  clearSlugFeedback($target);
  $target.addClass('is-valid');
}

function stringToSlug(str){
  return (
    str.toLowerCase()
    .replace(/\.[^/.]+$/, "")
    .replace(/ /g,'-')
    .replace(/[^\w-]+/g,'')
  );
}

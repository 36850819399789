$(document).on('ready turbolinks:load', function(){

  $('.js-input-counted').each(function(){
    var $textarea = $(this)
    var $counter = $textarea.parent().find('.js-input-counted-target')
    var $counterContainer = $counter.parent()

    updateCounterInput($textarea, $counter, $counterContainer)

    $('.note-editable').on('input', function(){
      var $noteTextarea= $(this)
      updateCounterInput($textarea, $counter, $counterContainer, $noteTextarea)
    })
  })

})

function strip_html_tags(str) {
  return str.replace(/<[^>]*>?/gm, '');
}

function updateCounterInput($textarea, $counter, $container, $noteTextarea= null){
  var minLength =  $textarea.data('minlength')
  var noteTextLength = $noteTextarea && strip_html_tags($noteTextarea.text()).length 
  var strippedInputCountedContainerLength = strip_html_tags($('.js-input-counted').val()).length

  function currentLength(){
    if (noteTextLength) {
      return noteTextLength
    } else if(strippedInputCountedContainerLength > 0) {
      return strippedInputCountedContainerLength
    } else {
      return 0
    }
  }

  $counter.text(`${currentLength()}/${minLength}`)
  if (currentLength() < minLength) {
    $container.addClass('error text-danger')
    $('.fa-exclamation-triangle').show()
  } else {
    $container.removeClass('error text-danger')
    $('.fa-exclamation-triangle').hide()
  }
}